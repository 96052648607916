import React from 'react'
import { Dialog, Grow, Slide } from '@mui/material'
import './MixtilesDialog.scss'
import classNames from 'classnames'
import CloseButton from '../TopBar/CloseButton/CloseButton'
import useScreenSize from '../../hooks/useScreenSize'
import { useDialogRoute } from '../../hooks/useDialogRoute'

const SlideUpTransition = React.forwardRef(
  function SlideUpTransition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  }
)

const GrowTransition = React.forwardRef(function GrowTransition(props, ref) {
  return <Grow ref={ref} {...props} />
})

const transitions = {
  slide: SlideUpTransition,
  grow: GrowTransition,
  noTransition: undefined,
}

const DEFAULT_DIALOG_MAX_WIDTH = 'md'

function MixtilesDialog({
  className,
  open,
  closeButton,
  transition,
  fullScreen,
  scroll,
  onEntered,
  onDismiss,
  keepMounted,
  children,
  closeButtonOnDialog,
  darkCloseButton,
  maxWidth,
  showOnTop = false,
  hideBackdrop = false,
}) {
  const { isDialogOpen, onDialogClose } = useDialogRoute(open, onDismiss)
  const isMobile = !useScreenSize('md')
  const isFullScreen = fullScreen === undefined ? isMobile : fullScreen
  const transitionComp =
    transitions[transition || (fullScreen ? 'slide' : 'grow')]
  return (
    <>
      {closeButton && isDialogOpen && (
        <CloseButton
          full
          circle
          action={onDialogClose}
          {...closeButton}
          className={classNames(
            closeButton.className,
            'mixtiles-dialog-close-button'
          )}
        />
      )}
      <Dialog
        fullScreen={isFullScreen}
        scroll={scroll}
        classes={{
          paper: classNames('mixtiles-dialog-paper', className),
          paperFullScreen: 'mixtiles-dialog-paper-fullscreen',
          root: classNames('mixtiles-dialog-root', {
            'on-top': showOnTop,
            'not-full-screen': !isFullScreen,
          }),
        }}
        onClose={onDialogClose}
        TransitionProps={{ onEntered }}
        TransitionComponent={transitionComp}
        keepMounted={keepMounted}
        maxWidth={maxWidth === undefined ? DEFAULT_DIALOG_MAX_WIDTH : maxWidth}
        open={isDialogOpen || false}
        hideBackdrop={hideBackdrop}
      >
        {closeButtonOnDialog ? (
          <CloseButton
            action={onDialogClose}
            className="mixtiles-dialog-close-button-on-dialog"
            dark={darkCloseButton}
          />
        ) : null}
        {children}
      </Dialog>
    </>
  )
}

export const MixtilesFloatingModal = MixtilesDialog
export default MixtilesDialog
