import { useEffect, useState, PropsWithChildren } from 'react'

export default function NoSSR({ children }: PropsWithChildren) {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) {
    return null
  }

  return children
}
