import { GiftCardBalance, GiftCardOffer } from './GiftCard.types'
import {
  GiftCardStatus,
  GiftCardType,
  ProductIds,
} from '@mixtiles/web-backend-shared'
import { CheckoutSummaryProduct } from '../../components/Pricing/types/checkout.types'
import { PRODUCT_TYPES } from '../../services/ProductTypeState'

export function formatGiftCardOfferToProducts(
  offer: GiftCardOffer,
  productType: PRODUCT_TYPES
): CheckoutSummaryProduct[] {
  const isBusiness = productType === PRODUCT_TYPES.BUSINESS
  if (offer.type === GiftCardType.VALUE) {
    return [
      {
        id: ProductIds.GIFT_CARD_VALUE,
        value: offer.value,
        quantity: isBusiness ? offer.recipientsCount : 1,
      },
    ]
  } else if (offer.type === GiftCardType.TILES) {
    return [
      {
        id: ProductIds.GIFT_CARD_TILES,
        value: offer.value,
        quantity: isBusiness ? offer.recipientsCount : 1,
      },
    ]
  } else {
    throw new Error(`Unknown gift card type`)
  }
}

export function isValidGiftCard({ balance, status }: GiftCardBalance) {
  return (
    balance &&
    balance > 0 &&
    [GiftCardStatus.ACTIVE, GiftCardStatus.SCHEDULED].includes(status)
  )
}
