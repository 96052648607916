import StorageManager from './StorageManager'
import { translateManager as t } from './TranslateManager'
import { addressManager } from './AddressManager'
import { analytics } from './Analytics/Analytics'
import fromPairs from 'lodash/fromPairs'
import { getStripeAmountToCharge } from '../utils/utils'
import { extractApiError, mixtilesAxios } from '../utils/ApiUtils'
import { countryManager } from './CountryManager'
import { logger } from './logger'
import capitalize from 'lodash/capitalize'
import { getOrderLocalId } from './OrderUtils'
import { PaymentMethodType } from './PaymentManager.types'

// Changing these constants can affect the PaymentConfig in Parse
const KEY_STRIPE_CUSTOMER = 'stripeCustomer'
const KEY_PAYMENT_METHOD_TYPE = 'paymentMethodType'
const KEY_PAYMENT_CURRENT_CARD = 'paymentCurrentCard'
const KEY_PAYMENT_METHOD_ID = 'paymentMethodId'
const KEY_STRIPE_ACCOUNT_NAME = 'stripeAccount'
const KEY_PAYPAL_ACCOUNT_NAME = 'paypalAccount'

const ADDITIONAL_PAYMENT_INFO_KEYS = [
  KEY_PAYMENT_METHOD_TYPE,
  KEY_PAYMENT_METHOD_ID,
  KEY_STRIPE_ACCOUNT_NAME,
  KEY_PAYPAL_ACCOUNT_NAME,
]

// Stores the payment method type that was selected in the payment element
// It is required because in the past the credit card popup contained only credit card option,
// but since adding the PaymentElement, this popup can contain other payment methods
const KEY_PAYMENT_ELEMENT_METHOD_TYPE = 'paymentElementMethodType'

export const PaymentMethodTypeDisplay = {
  [PaymentMethodType.CREDIT_CARD]: 'Credit Card',
  [PaymentMethodType.PAYPAL]: 'PayPal',
  [PaymentMethodType.APPLE_PAY]: 'Apple Pay',
  [PaymentMethodType.GOOGLE_PAY]: 'Google Pay',
  [PaymentMethodType.LINK]: 'Link',
  [PaymentMethodType.AFTERPAY]: 'Afterpay',
  [PaymentMethodType.AFFIRM]: 'Affirm',
  [PaymentMethodType.KLARNA]: 'Klarna',
  [PaymentMethodType.AMAZON_PAY]: 'Amazon Pay',
  [PaymentMethodType.CASH_APP]: 'Cash App Pay',
}

// Returns false for payment methods that are supported only using PaymentElement and were enabled
// in the Stripe dashboard (link, BNPL etc.)
export function isSupportedWithoutPaymentElement(paymentMethodType) {
  return [
    PaymentMethodType.CREDIT_CARD,
    PaymentMethodType.PAYPAL,
    PaymentMethodType.APPLE_PAY,
    PaymentMethodType.GOOGLE_PAY,
  ].includes(paymentMethodType)
}

// The separation to different stripe accounts is done in order to avoid exchange rates that we have to
// pay when charging the customer in a different currency then the bank's currency in the Stripe account.
// The default currency that we will use is US dollars.
// Same for PayPal ^
const DEFAULT_STRIPE_ACCOUNT_NAME = 'US'
const OLD_PAYPAL_ACCOUNT_NAME = 'US' // we obsolete this account so old users should migrate to new account
const DEFAULT_PAYPAL_ACCOUNT_NAME = 'US_2'

// relevant european countries according to Finance regarding European credit cards. (including Israel)
// we're going to use the same list to also to PayPal
const COUNTRIES_FOR_EUROPEAN_PAYMENT = [
  'AL',
  'AD',
  'AT',
  'BY',
  'BE',
  'BA',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FO',
  'FI',
  'FR',
  'DE',
  'GI',
  'GR',
  'HU',
  'IS',
  'IE',
  'IM',
  'IT',
  'RS',
  'LV',
  'LI',
  'LT',
  'LU',
  'MK',
  'MT',
  'MD',
  'MC',
  'ME',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'RU',
  'SM',
  'RS',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'UA',
  'GB',
  'VA',
  'RS',
  'IL',
  'KZ',
  'AM',
  'TR',
  'AZ',
  'GE',
]

// STRIPE DATA FOR PAYMENTS
const STRIPE_ACCOUNTS_DATA = {
  NL: {
    country: 'NL',
    bankCurrency: 'eur',
    userCountries: COUNTRIES_FOR_EUROPEAN_PAYMENT,
  },
  US: {
    country: 'US',
    bankCurrency: 'usd',
    userCountries: ['US'],
  },
}

// PAYPAL DATA FOR PAYMENTS
const PAYPAL_ACCOUNTS_DATA = {
  NL: {
    bankCurrency: 'eur',
    userCountries: COUNTRIES_FOR_EUROPEAN_PAYMENT,
  },
  US_2: {
    bankCurrency: 'usd',
    userCountries: ['US'],
  },
}

class PaymentManager {
  static async createCustomerSetupIntent() {
    logger.info(`PaymentManager.createSetupIntent paymentMethodId: ${this.getStripePaymentMethodId()} 
                          customerId: ${this.getStripeCustomer().id}
                          stripeAccountName: ${this.getStripeAccountName()}`)

    const res = await mixtilesAxios.post('v4/stripe/createSetupIntent', {
      customerId: this.getStripeCustomer().id,
      stripeAccountName: this.getStripeAccountName(),
    })

    if (res.data && res.data.success) {
      return res.data.success.client_secret
    } else {
      throw new Error(
        'failed creating setup intent, response format is not valid'
      )
    }
  }

  static async confirmCardSetup({ stripe, handleActions }) {
    try {
      const setupIntentClientSecret = await this.createCustomerSetupIntent()
      return await stripe.confirmCardSetup(
        setupIntentClientSecret,
        {
          payment_method: this.getStripePaymentMethodId(),
        },
        { handleActions }
      )
    } catch (e) {
      const error = extractApiError(e) || e
      logger.error('failed confirming card setup', error)
      return { error }
    }
  }

  static async createPaymentIntent(
    orderPriceSummary,
    isStripePaymentElement = false
  ) {
    // this method is called when 'place order' is clicked and before the
    // photos finished to upload, it will just get a funds hold confirmation
    // and return a paymentIntentId which we can use later to make the
    // actual charge
    logger.info(`PaymentManager.createPaymentIntent paymentMethodId: ${this.getStripePaymentMethodId()} 
                          customerId: ${this.getStripeCustomer()?.id}
                          stripeAccountName: ${this.getStripeAccountName()}`)

    const apiPath = isStripePaymentElement
      ? 'v4/stripe/createPaymentIntent'
      : 'v3/stripe/createPaymentIntent'
    return mixtilesAxios.post(apiPath, {
      paymentMethodId: this.getStripePaymentMethodId(),
      customerId: this.getStripeCustomer()?.id,
      stripeAccountName: this.getStripeAccountName(),
      totalCost: orderPriceSummary.totalPrice,
      currency: orderPriceSummary.currency,
      localId: getOrderLocalId(),
    })
  }

  static canMakeAppleOrGooglePay({ stripe }) {
    // initialize payment request with default parameters. This way we know if the client
    // has a configured payment method that can be used with PaymentRequest API.
    const stripeAccount = this.getStripeAccount()
    const paymentRequest = stripe.paymentRequest({
      country: stripeAccount.country,
      currency: stripeAccount.bankCurrency,
      total: {
        label: t.get('order.checkout.payment_summary_rows.total'),
        amount: 0,
      },
      displayItems: [],
      requestPayerEmail: true,
      requestPayerName: true,
      // TODO: add request shipping and convert address to our format.
      // requestShipping: true,
    })

    return paymentRequest.canMakePayment().then((result) => {
      return {
        ...this._canMakeApplePay(paymentRequest, result),
        ...this._canMakeGooglePay(paymentRequest, result),
      }
    })
  }

  static _canMakeApplePay(paymentRequest, stripePaymentResult) {
    if (stripePaymentResult?.applePay) {
      analytics.setUserProperties({ 'Has Apple Pay': true })
      if (!PaymentManager.getSelectedPaymentMethodType()) {
        analytics.track('Apple Pay Set By Default')
        PaymentManager.useApplePay()
      }
      return {
        canMakePayment: true,
        paymentRequest,
        paymentType: PaymentMethodType.APPLE_PAY,
        canMakeApplePay: true,
      }
    }
    return { canMakeApplePay: false }
  }

  static _canMakeGooglePay(paymentRequest, stripePaymentResult) {
    if (stripePaymentResult?.googlePay) {
      analytics.setUserProperties({ 'Has Google Pay': true })
      if (!PaymentManager.getSelectedPaymentMethodType()) {
        analytics.track('Google Pay Set By Default')
        PaymentManager.useGooglePay()
      }
      return {
        canMakePayment: true,
        paymentRequest,
        paymentType: PaymentMethodType.GOOGLE_PAY,
        canMakeGooglePay: true,
      }
    }
    return { canMakeGooglePay: false }
  }

  static updatePaymentRequest({
    paymentRequest,
    orderPriceSummary,
    displayItems,
  }) {
    const totalLabel = t.get('order.checkout.payment_summary_rows.total')
    paymentRequest.update({
      currency: orderPriceSummary.currency.toLowerCase(),
      total: {
        label: totalLabel,
        amount: getStripeAmountToCharge({
          totalCost: orderPriceSummary.totalPrice,
          currency: orderPriceSummary.currency,
        }),
      },
      displayItems,
    })
  }

  // Returns whether the payment method can be saved for future use: https://stripe.com/docs/payments/payment-methods#usage
  // For example some BNPL payment methods cannot be attached to Customers.
  static isReusablePaymentMethod(paymentMethodType) {
    return [
      PaymentMethodType.CREDIT_CARD,
      PaymentMethodType.PAYPAL,
      PaymentMethodType.APPLE_PAY,
      PaymentMethodType.GOOGLE_PAY,
      PaymentMethodType.LINK,
    ].includes(paymentMethodType)
  }

  static async addStripePaymentMethod(
    stripePaymentMethod,
    email,
    paymentMethodType = PaymentMethodType.CREDIT_CARD
  ) {
    if (this.isReusablePaymentMethod(paymentMethodType)) {
      await this.savePaymentMethodToCustomer(stripePaymentMethod, email)
    }

    this.savePaymentDataLocally(paymentMethodType, stripePaymentMethod)

    return true
  }

  static async savePaymentMethodToCustomer(stripePaymentMethod, email) {
    // getting stripe customer from local storage
    let stripeCustomer = PaymentManager.getStripeCustomer()
    let response
    if (stripeCustomer?.id == null) {
      // if there is no stripe customer, we then create a new one
      const data = {
        paymentMethodId: stripePaymentMethod.id,
        email,
        stripeAccountName: this.getStripeAccountName(),
      }
      logger.info('Creating new stripe customer', data)
      response = await mixtilesAxios.post('v3/stripe/createCustomer', data)
    } else if (stripePaymentMethod.id !== this.getStripePaymentMethodId()) {
      // if stripe customer exists, we attach this new
      // payment method to it.
      const data = {
        paymentMethodId: stripePaymentMethod.id,
        customerId: stripeCustomer.id,
        stripeAccountName: this.getStripeAccountName(),
        email,
      }
      response = await mixtilesAxios.post('v3/stripe/attachPaymentMethod', data)
    }
    if (response.data && response.data.success) {
      stripeCustomer = response.data.success
    } else if (response.data.error) {
      throw response.data.error
    }

    // save the customer object to local storage
    StorageManager.set(KEY_STRIPE_CUSTOMER, stripeCustomer)
  }

  static savePaymentDataLocally(paymentMethodType, paymentData) {
    // set the payment method
    StorageManager.set(KEY_PAYMENT_METHOD_TYPE, paymentMethodType)

    // save the payment method id to the local storage. This will be required later to make payments.
    StorageManager.set(KEY_PAYMENT_METHOD_ID, paymentData.id)

    // Store paymentElementMethodType (or remove it for ApplePay and PayPal)
    if (
      this.isPaymentMethodGoogleOrApple(paymentMethodType) ||
      paymentMethodType === PaymentMethodType.PAYPAL
    ) {
      StorageManager.remove(KEY_PAYMENT_ELEMENT_METHOD_TYPE)
    } else {
      StorageManager.set(KEY_PAYMENT_ELEMENT_METHOD_TYPE, paymentMethodType)
    }

    if (paymentMethodType === PaymentMethodType.CREDIT_CARD) {
      // save the last card added by the user to the local storage
      StorageManager.set(KEY_PAYMENT_CURRENT_CARD, paymentData.card)
    } else {
      // Remove the card data from local storage because we have overridden KEY_PAYMENT_METHOD_ID
      StorageManager.remove(KEY_PAYMENT_CURRENT_CARD)
    }
  }

  static shouldRoundForPaypal(currency, value) {
    if (currency === 'JPY') {
      return Math.round(value)
    } else {
      return parseFloat(value.toFixed(2))
    }
  }

  static async initiatePaypalPayment(orderPriceSummary, address) {
    const shippingTotal = this.shouldRoundForPaypal(
      orderPriceSummary.currency,
      orderPriceSummary.shippingCost || 0
    )
    const taxTotal = this.shouldRoundForPaypal(
      orderPriceSummary.currency,
      orderPriceSummary.tax
    )
    const amount = this.shouldRoundForPaypal(
      orderPriceSummary.currency,
      orderPriceSummary.totalPrice
    )
    const itemTotal = amount - shippingTotal - taxTotal

    const params = {
      order_size: orderPriceSummary.totalItems,
      shipping_total: shippingTotal,
      tax_total: taxTotal,
      amount,
      item_total: itemTotal,
      currency_code: orderPriceSummary.currency,
      address: addressManager.normalizeAddress(address),
      paypalAccountName: this.getPayPalAccountName(),
      product_type: orderPriceSummary.productType || 'mixtiles',
    }
    const { paypal_transaction_id: transactionId } = await mixtilesAxios
      .post('v1/paypal/payment', params)
      .then((response) => response.data)

    return transactionId
  }

  static isPaymentMethodGoogleOrApple(paymentMethodType) {
    return [PaymentMethodType.GOOGLE_PAY, PaymentMethodType.APPLE_PAY].includes(
      paymentMethodType
    )
  }

  static usePayPal() {
    this.setPaymentMethodType(PaymentMethodType.PAYPAL)
  }

  static useApplePay() {
    this.setPaymentMethodType(PaymentMethodType.APPLE_PAY)
  }

  static useGooglePay() {
    this.setPaymentMethodType(PaymentMethodType.GOOGLE_PAY)
  }

  static useCreditCard() {
    this.setPaymentMethodType(PaymentMethodType.CREDIT_CARD)
  }

  static setPaymentMethodType(type) {
    StorageManager.set(KEY_PAYMENT_METHOD_TYPE, type)
    analytics.track('Checkout - Payment Method Added', { paymentMethod: type })
  }

  static getPayPalKey() {
    const paypalAccountName = this.getPayPalAccountName()
    switch (paypalAccountName) {
      case 'US':
        return window.KEYS.paypalUSClientId
      case 'US_2':
        return window.KEYS.paypalUS2ClientId
      case 'NL':
        return window.KEYS.paypalNLClientId
      default:
        logger.warning('Missing paypal key for given paypal account name', {
          paypalAccountName,
        })
        return window.KEYS.paypalUSClientId
    }
  }

  static getPayPalAccountName() {
    // Get cached paypal account if exists. Otherwise, will choose paypal account by user's country.
    let paypalAccountName = StorageManager.get(KEY_PAYPAL_ACCOUNT_NAME)
    if (paypalAccountName && paypalAccountName !== OLD_PAYPAL_ACCOUNT_NAME) {
      return paypalAccountName
    }

    // Choose PayPal account based on the pricing currency, in order to avoid high exchange rates in PayPal.
    paypalAccountName = DEFAULT_PAYPAL_ACCOUNT_NAME

    const userCountry = countryManager.getPricingCountry()
    for (const accountName in PAYPAL_ACCOUNTS_DATA) {
      if (
        PAYPAL_ACCOUNTS_DATA[accountName].userCountries.includes(
          userCountry.toUpperCase()
        )
      ) {
        paypalAccountName = accountName
        break
      }
    }

    this.setPayPalAccountName(paypalAccountName)
    return paypalAccountName
  }

  static setPayPalAccountName(paypalAccountName) {
    StorageManager.set(KEY_PAYPAL_ACCOUNT_NAME, paypalAccountName)
  }

  static getPayPalTransactionId() {
    return this.payPalTransactionId
  }

  static setPayPalTransactionId(payPalTransactionId) {
    this.payPalTransactionId = payPalTransactionId
  }

  static clearData() {
    StorageManager.remove(KEY_STRIPE_CUSTOMER)
    StorageManager.remove(KEY_PAYMENT_CURRENT_CARD)
    this.clearPaymentMethod()
    StorageManager.remove(KEY_STRIPE_ACCOUNT_NAME)
    StorageManager.remove(KEY_PAYPAL_ACCOUNT_NAME)
    this.setPayPalTransactionId(null)
    StorageManager.remove(KEY_PAYMENT_ELEMENT_METHOD_TYPE)
  }

  static clearPaymentMethod() {
    StorageManager.remove(KEY_PAYMENT_METHOD_TYPE)
    StorageManager.remove(KEY_PAYMENT_METHOD_ID)
  }

  static getStripeKey() {
    const stripeAccountName = this.getStripeAccountName()
    switch (stripeAccountName) {
      case 'US':
        return window.KEYS.stripeUSKey
      case 'NL':
        return window.KEYS.stripeNLKey
      default:
        logger.warning('Missing stripe key for given stripe account name', {
          stripeAccountName,
        })
        return window.KEYS.stripeUSKey
    }
  }

  static getStripeAccountName() {
    // Get cached stripe account if exists. Otherwise, will choose stripe account by user's country.
    let stripeAccountName = StorageManager.get(KEY_STRIPE_ACCOUNT_NAME)
    if (stripeAccountName) {
      return stripeAccountName
    }

    if (this.getStripeCustomer()) {
      // Stripe customer exists in a specific stripe account, so if stripe customer is already created, but
      // no stripe account name is cached in the local storage, should use old default stripe account.
      this.setStripeAccountName(DEFAULT_STRIPE_ACCOUNT_NAME)
      return DEFAULT_STRIPE_ACCOUNT_NAME
    }

    // Choose Stripe account based on the pricing currency, in order to avoid high exchange rates in Stripe.
    stripeAccountName = DEFAULT_STRIPE_ACCOUNT_NAME
    const userCountry = countryManager.getPricingCountry()
    for (const accountName in STRIPE_ACCOUNTS_DATA) {
      if (
        STRIPE_ACCOUNTS_DATA[accountName].userCountries.includes(
          userCountry.toUpperCase()
        )
      ) {
        stripeAccountName = accountName
        break
      }
    }

    this.setStripeAccountName(stripeAccountName)
    return stripeAccountName
  }

  static getStripeAccount() {
    return STRIPE_ACCOUNTS_DATA[this.getStripeAccountName()]
  }

  static setStripeAccountName(stripeAccountName) {
    StorageManager.set(KEY_STRIPE_ACCOUNT_NAME, stripeAccountName)
  }

  static getStripeCustomer() {
    return StorageManager.get(KEY_STRIPE_CUSTOMER)
  }

  static getStripePaymentMethodId() {
    return StorageManager.get(KEY_PAYMENT_METHOD_ID)
  }

  static getSelectedPaymentMethodType() {
    const selectedPaymentMethod = StorageManager.get(KEY_PAYMENT_METHOD_TYPE)
    // Solves an issue when CREDIT_CARD was set, but no credit card was added (user closed the dialog without adding one)
    if (
      selectedPaymentMethod === PaymentMethodType.CREDIT_CARD &&
      !this.getStripePaymentMethodId()
    ) {
      return null
    }
    return selectedPaymentMethod
  }

  static getPaymentMethodForOrder(payPalTransactionId = null) {
    const selectedPaymentMethodType =
      PaymentManager.getSelectedPaymentMethodType()
    const paymentMethodId = this.getStripePaymentMethodId()
    switch (selectedPaymentMethodType) {
      case PaymentMethodType.CREDIT_CARD:
        return {
          type: selectedPaymentMethodType,
          ...PaymentManager.getSelectedCard(),
        }
      case PaymentMethodType.PAYPAL:
        return {
          type: PaymentMethodType.PAYPAL,
          transactionId:
            payPalTransactionId || PaymentManager.getPayPalTransactionId(),
        }
      case undefined:
      case null:
        logger.warning('Received selected payment method without a type.', {
          paymentMethodId,
        })
        return null
      default:
        return { type: selectedPaymentMethodType, paymentMethodId }
    }
  }

  static getPaymentMethodText(paymentMethodType = null) {
    paymentMethodType = paymentMethodType || this.getSelectedPaymentMethodType()
    if (paymentMethodType === PaymentMethodType.PAYPAL) {
      return 'Paying with PayPal'
    } else if (paymentMethodType === PaymentMethodType.APPLE_PAY) {
      return 'Paying with Apple Pay'
    } else if (paymentMethodType === PaymentMethodType.GOOGLE_PAY) {
      return 'Paying with Google Pay'
    } else if (paymentMethodType === PaymentMethodType.CREDIT_CARD) {
      const creditCard = PaymentManager.getSelectedCard()

      if (creditCard) {
        // Solves a bug where CREDIT_CARD is set but no credit card was added
        return `${capitalize(creditCard.brand)} (${t
          .get('order.checkout.add_payment_options.credit_card_ends_with', {
            v1: creditCard.last4,
          })
          .toLowerCase()})`
      }
    }
    return t.get('order.checkout.add_payment')
  }

  static getSelectedCard() {
    const stripeCustomer = PaymentManager.getStripeCustomer()
    const currentCard = StorageManager.get(KEY_PAYMENT_CURRENT_CARD)
    if (stripeCustomer == null || !currentCard) {
      return null
    }
    return currentCard
  }

  static async loadStripeCustomerFromAccount(account) {
    const { paymentConfig } = account
    if (paymentConfig) {
      logger.info(
        `loadStripeCustomerFromAccount: ${paymentConfig.stripeCustomerId} stripeAccount: ${paymentConfig.stripeAccount}`
      )
      StorageManager.set(KEY_STRIPE_CUSTOMER, {
        id: paymentConfig.stripeCustomerId,
      })
      StorageManager.set(KEY_PAYMENT_METHOD_TYPE, PaymentMethodType.CREDIT_CARD)
      this.setStripeAccountName(paymentConfig.stripeAccount)
    }
  }

  static shouldLoadCardFromStripe() {
    const stripeCustomer = PaymentManager.getStripeCustomer()
    return (
      stripeCustomer?.id &&
      (!StorageManager.get(KEY_PAYMENT_CURRENT_CARD) ||
        !StorageManager.get(KEY_PAYMENT_METHOD_ID))
    )
  }

  static async loadCardFromStripe() {
    const stripeCustomer = PaymentManager.getStripeCustomer()
    logger.info(`loadCardFromStripe stripeCustomerId: ${stripeCustomer.id}`)

    return mixtilesAxios
      .post('v3/stripe/listPaymentMethods', {
        customer: stripeCustomer.id,
        stripeAccountName: this.getStripeAccountName(),
      })
      .then((res) => {
        if (res.data.success.data.length === 0) {
          return
        }
        const paymentId = res.data.success.data[0].id
        const cc = res.data.success.data[0].card

        // save the last card added by the user to the local storage
        StorageManager.set(KEY_PAYMENT_CURRENT_CARD, cc)
        // save the payment method id to the local storage.
        // this will be required later to make payments
        StorageManager.set(KEY_PAYMENT_METHOD_ID, paymentId)
      })
      .catch((error) => {
        logger.error('Failed to load card from stripe', error)
      })
  }

  static getPaymentInfo() {
    return {
      ...fromPairs(
        ADDITIONAL_PAYMENT_INFO_KEYS.map((key) => [
          key,
          StorageManager.get(key),
        ])
      ),
      stripeCustomerId: (StorageManager.get(KEY_STRIPE_CUSTOMER) || {}).id,
    }
  }

  static async getProductWithPrices(productName) {
    return (
      await mixtilesAxios.get('v4/stripe/retrieveProductWithPrices', {
        params: {
          stripeAccountName: this.getStripeAccountName(),
          productName,
        },
      })
    ).data
  }

  static getPaymentElementMethodType() {
    return StorageManager.get(KEY_PAYMENT_ELEMENT_METHOD_TYPE)
  }
}

export default PaymentManager
