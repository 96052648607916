// These breakpoints will be used mainly for styled components to detect onLargeScreen etc.
// And for Material UI components like Grid and Dialog
import { ThemeOptions } from '@mui/material'
import { ArtThemeInterface, PhotosThemeInterface } from './interfaces'

export const BREAKPOINTS = {
  xs: 320, // Just because 0 is causing issues with styled-breakpoints
  sm: 480,
  md: 769,
  lg: 1280,
  xl: 1600,
  xxl: 1921,
}

export const generateMuiTheme = (
  themeColors: PhotosThemeInterface['colors'] | ArtThemeInterface['colors'],
  themeFontFamily = 'MixtilesSans'
): ThemeOptions => ({
  breakpoints: {
    values: BREAKPOINTS,
  },

  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: '1600 !important',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: '1500 !important',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          zIndex: '1500 !important',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&$selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
          '&$selected:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            backgroundColor: 'white',
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: 0,
          },
        },
        content: {
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          '&:hover': {
            backgroundColor: themeColors.primary,
            boxShadow: '0 3px 12px rgba(255, 21, 134, 0.3)',
          },
          '&:disabled': {
            opacity: 0.3,
            backgroundColor: themeColors.primary,
            color: themeColors.white,
          },
        },
        outlinedPrimary: {
          borderWidth: 2,
          borderColor: themeColors.primary,
          '&:hover': {
            borderWidth: 2,
            boxShadow: '0 3px 12px rgba(255, 21, 134, 0.3)',
          },
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: `rgb(from ${themeColors.grayAccent} r g b / 50%)`,
          border: `1px solid rgb(from ${themeColors.grayMedium} r g b / 50%)`,
          color: themeColors.grayDark,
          boxShadow: 'none',
          transition: 'transform 0.3s',
          '&:is(:hover, :focus)': {
            backgroundColor: `rgb(from ${themeColors.grayAccent} r g b / 50%)`,
            boxShadow: 'none',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 47,
          height: 25,
          padding: 0,
          display: 'flex',
        },
        switchBase: {
          padding: 2,
          color: themeColors.gray3,
          top: 3,
          left: 3,
          '&$checked': {
            transform: 'translateX(22px)',
            color: themeColors.white,
            '& + $track': {
              opacity: 1,
              backgroundColor: themeColors.mainPink,
              borderColor: themeColors.mainPink,
            },
          },
        },
        thumb: {
          color: themeColors.white,
          width: 15,
          height: 15,
          boxShadow: 'none',
        },
        track: {
          border: `1px solid ${themeColors.gray3}`,
          borderRadius: '14px',
          boxSizing: 'border-box',
          opacity: 1,
          backgroundColor: themeColors.gray3,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontWeight: 600,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '14px',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },

  typography: {
    fontFamily: [themeFontFamily, 'sans-serif'].join(','),
    button: {
      fontWeight: 600,
      textTransform: 'none',
    },
  },

  palette: {
    primary: {
      main: themeColors.primary,
    },
  },
})
