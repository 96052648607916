import {
  fallbackUntilMatch,
  FRAME_COLORS,
  isPricingProductsEqual,
  MATERIAL_TYPES,
  PricingProduct,
  TILE_SIZES,
} from '@mixtiles/web-backend-shared'
import {
  MIXED_COLORS,
  MIXED_SIZES,
} from '../../../pages/PhotoStyler/TileDesignerConsts'
import {
  Discount,
  DiscountTileSize,
  DiscountType,
  DiscountsAPI,
  DiscountTileColor,
} from '../types/discount.types'
import {
  DISCOUNT_TYPE_BUNDLE,
  DISCOUNT_TYPE_BUYOVER,
  DISCOUNT_TYPE_BUYXGETY,
  DISCOUNT_TYPE_PERCENT,
} from '../../../services/PromoCodeManager'
import { calculateDiscountPercent } from '../../../utils/utils'
import {
  FetchDiscountResponse,
  FetchPricingItemResponse,
} from '../types/PricingProvider.types'

function formatDiscount({
  tileSize,
  matchingDiscount,
  currency,
  materialType,
  showPromoCodeBadge,
}: {
  tileSize: DiscountTileSize
  matchingDiscount: any
  currency: string
  materialType: MATERIAL_TYPES
  showPromoCodeBadge: boolean
}): Discount {
  let baseParams = {
    type: matchingDiscount.type,
    tileSize,
    promoCode: matchingDiscount.promocode || false,
    popular: matchingDiscount.popular || false,
    showPromoCodeBadge,
  }

  const metadataBaseParams = {
    discountId: matchingDiscount.discountId,
    discountType: matchingDiscount.type,
    materialType,
  }

  if (matchingDiscount.type === DiscountType.PERCENT) {
    return {
      ...baseParams,
      discount: {
        type: DiscountType.PERCENT,
        percent_off: matchingDiscount.percentageData.percentOff,
      },
      metadata: {
        ...metadataBaseParams,
        percentOff: matchingDiscount.percentageData.percentOff,
      },
    }
  } else if (matchingDiscount.type === DiscountType.BUNDLE) {
    return {
      ...baseParams,
      metadata: {
        ...metadataBaseParams,
        bundleTiles: matchingDiscount.bundleData.quantity,
        bundlePrice: matchingDiscount.bundleData.price,
        bundleCurrency: currency,
      },
    }
  } else if (matchingDiscount.type === DiscountType.BUYXGETY) {
    return {
      ...baseParams,
      metadata: {
        ...metadataBaseParams,
        buy: matchingDiscount.buyXGetYData.buy,
        get: matchingDiscount.buyXGetYData.get,
      },
    }
  } else if (matchingDiscount.type === DiscountType.BUYOVER) {
    return {
      ...baseParams,
      metadata: {
        ...metadataBaseParams,
        buyOverPrice: matchingDiscount.buyOverData.buyOverPrice,
        buyOverDiscount: matchingDiscount.buyOverData.buyOverDiscountPercentage,
        bundleCurrency: currency,
      },
    }
  } else {
    throw new Error(`Unsupported discount type: ${matchingDiscount.type}`)
  }
}

const CLASSIC_BUYOVER_DISCOUNT_ID = 'MIXED_SIZES'
const CANVAS_BUYOVER_DISCOUNT_ID = 'CANVAS_MIXED_SIZES'
const MIXED_COLORS_DISCOUNT_ID = 'MIXED_COLORS'

const MIXED_SIZES_DISCOUNT_IDS = [
  CLASSIC_BUYOVER_DISCOUNT_ID,
  CANVAS_BUYOVER_DISCOUNT_ID,
]

export function isMixedSizesDiscount(discountId: string): boolean {
  return MIXED_SIZES_DISCOUNT_IDS.includes(discountId)
}

export function getDiscountForSize({
  discounts,
  tileSize,
  currency,
  materialType,
  frameColor,
}: {
  discounts: DiscountsAPI
  tileSize: DiscountTileSize
  currency: string
  materialType: MATERIAL_TYPES
  frameColor?: DiscountTileColor
}): Discount | null {
  const mixedSizesDiscountId =
    materialType === MATERIAL_TYPES.CANVAS
      ? CANVAS_BUYOVER_DISCOUNT_ID
      : CLASSIC_BUYOVER_DISCOUNT_ID
  let matchingDiscount
  if (tileSize === MIXED_SIZES) {
    matchingDiscount = discounts.find(
      (discount) => discount.discountId === mixedSizesDiscountId
    )
  }

  if (!matchingDiscount) {
    if (frameColor === MIXED_COLORS) {
      matchingDiscount = discounts.find(
        (discount) => discount.discountId === MIXED_COLORS_DISCOUNT_ID
      )
    }
  }

  if (!matchingDiscount) {
    let pricingProduct: PricingProduct = {
      tileSize: tileSize as TILE_SIZES,
      materialType,
      frameColor: frameColor as FRAME_COLORS,
    }

    matchingDiscount = fallbackUntilMatch(
      pricingProduct,
      (pricingProduct: PricingProduct) =>
        discounts.find((discount) =>
          isPricingProductsEqual(discount.pricingProduct, pricingProduct)
        )
    )
  }

  if (!matchingDiscount) {
    return null
  }

  const showPromoCodeBadge =
    discounts.filter((discount) => discount.promocode).length === 1 &&
    matchingDiscount.promocode
  return formatDiscount({
    tileSize,
    matchingDiscount,
    currency,
    materialType,
    showPromoCodeBadge,
  })
}

/**
 * Receive a discount and a pricing item, and return how much percentage does
 * the discount represent - for example by checking the price of the bundle
 * compared to the original expected price without discount
 */
export function getDiscountPercentage({
  discount,
  pricingItem,
}: {
  discount: FetchDiscountResponse
  pricingItem: FetchPricingItemResponse
}): number | undefined {
  if (discount.value && pricingItem.value) {
    switch (discount.value.type) {
      case DISCOUNT_TYPE_BUYOVER:
        return discount.value.metadata.buyOverDiscount
      case DISCOUNT_TYPE_BUYXGETY:
        return calculateDiscountPercent(
          pricingItem.value * discount.value.metadata.buy,
          pricingItem.value *
            (discount.value.metadata.buy + discount.value.metadata.get)
        )
      case DISCOUNT_TYPE_PERCENT:
        return discount.value.metadata.percentOff
      case DISCOUNT_TYPE_BUNDLE:
        return calculateDiscountPercent(
          discount.value.metadata.bundlePrice,
          pricingItem.value * discount.value.metadata.bundleTiles
        )
    }
  }
}
