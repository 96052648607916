import React, { useContext, useState } from 'react'
import EmbeddedLogin from './EmbeddedLogin'
import { useAuth } from '../../services/AuthProvider/AuthProvider'
import StorageManager from '../../services/StorageManager'
import { isClient } from 'utils/runtimeUtils'
import NoSSR from 'utils/NoSSR'

const ONE_TIME_LOGIN_DISPLAYED = 'oneTimeLoginDisplayed'

type OpenParams = {
  isMixtilesPlusFlow?: boolean
  onLoginProcessStart?: () => void
  source?: string
  redirectTo?: string
}

type EmbeddedLoginContextType = {
  open: (params: OpenParams) => void
  openOnce: (params: OpenParams) => boolean
  isOpen: boolean
}

const EmbeddedLoginContext = React.createContext<
  EmbeddedLoginContextType | undefined
>(undefined)

export function withEmbeddedLogin(Component: any) {
  return React.forwardRef((props, ref) => (
    <EmbeddedLoginContext.Consumer>
      {(embeddedContext) => (
        <Component {...props} ref={ref} embeddedLogin={embeddedContext} />
      )}
    </EmbeddedLoginContext.Consumer>
  ))
}

export function useEmbeddedLogin() {
  const context = useContext(EmbeddedLoginContext)
  if (!context) {
    throw new Error('useEmbeddedLogin must be used within a SideMenuProvider')
  }

  return context
}

export default function EmbeddedLoginProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [isMixtilesPlusFlow, setIsMixtilesPlusFlow] = useState(false)
  const [onLoginProcessStart, setOnLoginProcessStart] = useState<
    () => void | undefined
  >(() => {})
  const [source, setSource] = useState<string>('')
  const [redirectTo, setRedirectTo] = useState<string>('')
  const { isAuthenticated } = useAuth()
  const [loginDisplayed, setLoginDisplayed] = useState(() =>
    isClient() ? StorageManager.get(ONE_TIME_LOGIN_DISPLAYED) : false
  )

  const open = ({
    isMixtilesPlusFlow = false,
    onLoginProcessStart = () => {},
    source = '',
    redirectTo = '',
  }) => {
    setIsMixtilesPlusFlow(isMixtilesPlusFlow)
    setOnLoginProcessStart(onLoginProcessStart)
    setSource(source)
    setRedirectTo(redirectTo)
    setIsOpen(true)
  }

  const openOnce = ({
    isMixtilesPlusFlow = false,
    onLoginProcessStart = () => {},
    source = '',
    redirectTo = '',
  }) => {
    if (!isAuthenticated && !loginDisplayed) {
      open({ isMixtilesPlusFlow, onLoginProcessStart, source, redirectTo })
      StorageManager.set(ONE_TIME_LOGIN_DISPLAYED, true)
      setLoginDisplayed(true)
      return true
    }
    return false
  }

  const onDismiss = () => {
    setIsOpen(false)
    StorageManager.set(ONE_TIME_LOGIN_DISPLAYED, false)
    setLoginDisplayed(false)
  }

  return (
    <EmbeddedLoginContext.Provider value={{ open, openOnce, isOpen }}>
      <NoSSR>
        <EmbeddedLogin
          open={isOpen}
          onDismiss={() => onDismiss()}
          isMixtilesPlusFlow={isMixtilesPlusFlow}
          onLoginProcessStart={onLoginProcessStart}
          source={source}
          redirectTo={redirectTo}
        />
      </NoSSR>
      {children}
    </EmbeddedLoginContext.Provider>
  )
}
