import pick from 'lodash/pick'
import StorageManager from '../StorageManager'
import {
  BookBasicData,
  Cover,
  Page,
} from '../../components/PhotoBook/PhotoBookProvider/PhotoBook.types'
import {
  SAVE_PHOTO_KEYS,
  StoredPage,
  StoredPhotoBook,
} from './photoBookSaver.types'
import { BookSize } from '@mixtiles/web-backend-shared'
import { RemotePhoto } from '../../components/PhotoBank/PhotoBank.types'
import {
  ACCOUNT_PHOTO_BOOK_STORAGE_KEY,
  PHOTO_BOOK_STORAGE_KEY,
} from '../../components/PhotoBook/PhotoBook.consts'
import { serializePhotoBooksMetadata } from './photoBookDTO'
import { saveOrderDraftApi } from '../../api/orderDraft.api'
import isEqual from 'lodash/isEqual'

export function getStorablePhotoBook(
  cover: Cover,
  pages: Page[],
  size: BookSize
): StoredPhotoBook {
  const pagesToSave: StoredPage[] = pages.map((page) => ({
    front: {
      photos: page.front.photos,
    },
    back: {
      photos: page.back.photos,
    },
  }))

  return {
    pages: pagesToSave,
    cover: {
      caption: cover.caption,
      photo: pick(cover.photo, SAVE_PHOTO_KEYS),
    },
    size,
  }
}

export const savePhotoBook = (
  cover: Cover | null,
  pages: Page[],
  size: BookSize,
  api: any,
  storedPhotos: RemotePhoto[]
) => {
  if (!cover) {
    return
  }

  const storedPhotoBook: StoredPhotoBook = getStorablePhotoBook(
    cover,
    pages,
    size
  )

  if (api.isAuthenticated) {
    const photoBookData = serializePhotoBooksMetadata({
      pages,
      cover,
      bookSize: size,
      remotePhotos: storedPhotos,
    })
    api.call(saveOrderDraftApi({ photoBook: photoBookData }))
  }
  StorageManager.set(PHOTO_BOOK_STORAGE_KEY, storedPhotoBook)
}

export const clearPhotoBook = () => {
  StorageManager.set(PHOTO_BOOK_STORAGE_KEY, null)
  StorageManager.set(ACCOUNT_PHOTO_BOOK_STORAGE_KEY, null)
}

export const loadPhotoBook = (
  storageKey = PHOTO_BOOK_STORAGE_KEY
): StoredPhotoBook | null => {
  const storedPhotoBook = StorageManager.get(storageKey)

  if (
    !storedPhotoBook ||
    !storedPhotoBook?.cover?.photo?.uid ||
    !storedPhotoBook.pages
  ) {
    return null
  }

  return storedPhotoBook
}

export const isPhotoBookEmpty = (storageKey?: string) => {
  return loadPhotoBook(storageKey) === null
}

const getBookBasicData = (storageKey: string): BookBasicData | undefined => {
  const data = loadPhotoBook(storageKey)
  if (data) {
    const captionText = data.cover.caption.text
    const coverPhotoUid = data.cover?.photo?.uid
    if (captionText && coverPhotoUid) {
      return { captionText, coverPhotoUid }
    }
  }
}

export const getBookBasicDataByStorageKey = (): {
  localStoragePhotoBook: BookBasicData | undefined
  accountPhotoBook: BookBasicData | undefined
  equal: boolean
} => {
  const localStoragePhotoBook = getBookBasicData(PHOTO_BOOK_STORAGE_KEY)
  const accountPhotoBook = getBookBasicData(ACCOUNT_PHOTO_BOOK_STORAGE_KEY)

  return {
    localStoragePhotoBook,
    accountPhotoBook,
    equal:
      !!localStoragePhotoBook &&
      !!accountPhotoBook &&
      isEqual(localStoragePhotoBook, accountPhotoBook),
  }
}

export const clearAccountPhotoBook = () =>
  StorageManager.set(ACCOUNT_PHOTO_BOOK_STORAGE_KEY, null)
