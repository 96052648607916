import { getUrlParam } from '../utils/UrlUtils'
import {
  ART_COLLECTION_HOME_URL,
  PHOTO_BOOK_HOMEPAGE_URL,
} from '../config/config'
import { emailCaptureManager } from './EmailCaptureManager'
import { ExperimentManager } from './ExperimentManager/ExperimentManager'
import type { Location } from '@remix-run/router'

export function isQuizFirst() {
  const utmSource = getUrlParam('utm_source')
  if (!utmSource) {
    return false
  }
  const isGoogleUser = utmSource.includes('googleads')
  const isEmailUser = utmSource === 'email'
  return !(isGoogleUser || isEmailUser)
}

export function shouldRedirectToEmailCapture(
  experimentManager: ExperimentManager,
  location: Location
): boolean {
  const shouldRedirect =
    isQuizFirst() &&
    emailCaptureManager.shouldCaptureEmail() &&
    !experimentManager.isEnabled('straight-to-photos')

  const isRedirectEndpoint =
    ['/', ART_COLLECTION_HOME_URL].includes(location.pathname) ||
    (location.pathname === PHOTO_BOOK_HOMEPAGE_URL &&
      !experimentManager.isEnabled('photo-books-onboarding'))

  return shouldRedirect && isRedirectEndpoint
}
