import { GIFT_CARD, ONBOARDING_URL, PHOTO_STYLER_URL } from '../config/config'
import * as queryString from 'query-string'
import StorageManager from './StorageManager'
import { ART_ROUTES, ART_SCREENS } from '../Art/config/art-consts'
import {
  getSearchParams,
  omitLocationParam,
  parseLocation,
  getQueryString as getQueryStringUtil,
} from '../utils/location'
import {
  CHOSEN_PRODUCT,
  GIFT_PRODUCT_OPTIONS,
} from '../pages/EmailCapturePage/EmailCapturePage.consts'
import { isQuizFirst } from './loginFlows'
import { FORCE_QUIZ_QUERY_PARAM } from './queryParam.consts'
import { artNavigationManager } from '../Art/services/ArtNavigationManager'
import { emailCaptureManager } from './EmailCaptureManager'
import { PRODUCT_TYPES } from './ProductTypeState'
import { remixNavigationManager } from 'services/remixNavigationManager'
import { isClient } from 'utils/runtimeUtils'

const CLASSIC_ONBOARDING_FIRST_PAGE_URL = ONBOARDING_URL

class NavigationManager {
  constructor() {
    this.historyLengthOnAppLoad = isClient()
      ? this._getCurrentHistoryLength()
      : 0
    this._nextOnFinishOnboarding = null
  }

  _getCurrentHistoryLength() {
    return window.history && window.history.length
  }

  _isFirstAppPage() {
    return (
      this.historyLengthOnAppLoad &&
      this.historyLengthOnAppLoad === this._getCurrentHistoryLength()
    )
  }

  isPreviousPageAnExternalLandingPage() {
    return (
      navigationManager._isFirstAppPage() &&
      (window.KEYS.authorizedLandingPages || []).some((proxyRoute) =>
        document.referrer.includes(proxyRoute)
      )
    )
  }

  // TODO: all these functions should receive their params in an object
  goToPage(
    location,
    state = undefined,
    keepUrlParams = false,
    keepHistory = true
  ) {
    const locationObject = parseLocation(location, keepUrlParams)

    remixNavigationManager.navigate(locationObject, {
      state,
      replace: !keepHistory,
    })
  }

  goToHomePage(productType) {
    if (productType === PRODUCT_TYPES.ART) {
      this.goToMixtilesArt()
    } else {
      this.goToPage('/')
    }
  }

  replace(location) {
    remixNavigationManager.navigate(parseLocation(location), {
      replace: true,
    })
  }

  /*
  Only replaces the given url params, without deleting any param or changing URL.
  Usually you should prefer using 'replace' method instead, that keeps only the mandatory params.
   */
  replaceParams(query, keepAllParams = true) {
    const location = window.location
    remixNavigationManager.navigate(
      {
        pathname: location.pathname,
        search: this.getQueryString(query, keepAllParams),
        hash: location.hash,
      },
      { replace: true }
    )
  }

  removeParams(params) {
    const location = window.location
    const allParams = new URLSearchParams(queryString.parse(location.search))
    params.forEach((p) => {
      allParams.delete(p)
    })
    remixNavigationManager.navigate(
      {
        pathname: location.pathname,
        search: allParams.toString(),
      },
      { replace: true }
    )
  }

  removeParam(param) {
    this.removeParams([param])
  }

  getQueryString(query, keepAllParams) {
    const params = getSearchParams(query, keepAllParams)
    return getQueryStringUtil(params)
  }

  setNextOnFinishOnboarding(returnToLocation) {
    const locationWithoutForceParam = omitLocationParam(
      returnToLocation,
      FORCE_QUIZ_QUERY_PARAM
    )
    this._nextOnFinishOnboarding = `${locationWithoutForceParam.pathname}?${locationWithoutForceParam.search}`
  }

  goNextOnFinishOnboarding() {
    if (this._nextOnFinishOnboarding) {
      remixNavigationManager.navigate(this._nextOnFinishOnboarding, {
        state: { source: 'Onboarding' },
      })
    } else if (isQuizFirst()) {
      this.goToPage('/')
    } else {
      this.goToMainPage()
    }
  }

  getPhotoPickingURL() {
    const chosenProduct =
      StorageManager.get(CHOSEN_PRODUCT) || GIFT_PRODUCT_OPTIONS.PICK_YOURSELF
    if (chosenProduct === GIFT_PRODUCT_OPTIONS.GIFT_CARD) {
      StorageManager.remove(CHOSEN_PRODUCT) // we want to redirect the user to the gift page only once
      return GIFT_CARD
    }

    if (emailCaptureManager.passedAnyOnboarding()) {
      return PHOTO_STYLER_URL
    }

    return CLASSIC_ONBOARDING_FIRST_PAGE_URL
  }

  goToPhotoPicking(keepUrlParams = false) {
    return this.goToPage(this.getPhotoPickingURL(), {}, keepUrlParams)
  }

  goToMainPage(productType) {
    if (productType === PRODUCT_TYPES.ART) {
      artNavigationManager.goToDiscovery()
    } else {
      this.goToPhotoPicking()
    }
  }

  goToMixtilesArt(arrivedFrom, newTab = false) {
    /* We need to refresh to page when going to art, since we need to reload the facebook pixel (which is different for art) */

    let destination = ART_ROUTES[ART_SCREENS.HOME]

    const query = arrivedFrom ? `?arrivedFrom=${arrivedFrom}` : ''
    destination += query
    if (newTab) {
      window.open(destination, '_blank')
    } else {
      window.location.href = destination
    }
  }
}

export const navigationManager = new NavigationManager()
