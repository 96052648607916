import StorageManager from './StorageManager'
import { analytics, EVENT_NAMES } from './Analytics/Analytics'
import { updateAccountApi } from '../api/account.api'
import { triggerApi } from '../api/apiProvider'
import { logger } from './logger'
import * as userEmail from './userEmail'
import { getCountrySource, getIPAddress, getLocale } from './countryService'
import { ServerSideAnalyticsPlatform } from './Analytics/ServerSideAnalytics'
import { setFacebookAdvancedMatching } from './Analytics/platforms/facebook'
import { userState } from './User/userState'
import { mixtilesAxios } from '../utils/ApiUtils'
import { translateManager } from './TranslateManager'
import { quicklookSupportExists } from '../utils/browserUtils'
import { getUserBrowserProperties } from '../utils/utils'
import { Subject } from 'rxjs'
import { experimentManager } from './ExperimentManager/ExperimentManager'
import { INITIAL_USER_KEY } from './userEmail'
import { isServer } from 'utils/runtimeUtils'
import { PRODUCT_TYPES, productTypeState } from './ProductTypeState'
import { setCookie } from 'typescript-cookie'

const USER_FULL_NAME_KEY = 'userName'
const ART_USER_KEY = 'Art User'
export const LAST_VISIT_DATE_KEY = 'lastVisitDate'

export const CAPTURE_EMAIL_SOURCE = {
  login: 'Login',
  onboarding: 'Onboarding',
  checkoutAddress: 'Checkout Address',
  checkoutPayment: 'Checkout Payment',
  checkoutGiftCard: 'Checkout Gift Card',
  artOnboarding: 'Art Onboarding',
  artProductPage: 'Art Product Page',
  crmLead: 'CRM Lead',
  orderCompleted: 'Order Completed',
}

const onEmailChange = new Subject()

export const getUserToken = () => UserManager.getUser().username

let initialUserParams

class UserManager {
  static init() {
    if (isServer()) return
    const email = UserManager.getUserEmail()
    if (email) {
      setFacebookAdvancedMatching(email)
    }
  }

  static setIsNewEmail(isNewEmail) {
    // This actually indicates if this email is new in the current session
    // and will be used for the create new order analytics
    this._isNewEmail = isNewEmail

    const email = this.getUserEmail()
    if (isNewEmail && email) {
      analytics.track(
        'New Email Captured',
        { Email: email },
        { serverSideAnalyticsPlatforms: [ServerSideAnalyticsPlatform.Facebook] }
      )
    }
  }

  static isNewEmail() {
    return this._isNewEmail
  }

  static getUser() {
    return userState.getUser()
  }

  static subscribeToExperimentFetch(callback) {
    return onEmailChange.subscribe(callback)
  }

  static clearData() {
    userEmail.clearUserEmail()
    StorageManager.remove(USER_FULL_NAME_KEY)
  }

  static initializeUserProperties() {
    const { countryCode, country } = getLocale()
    analytics.setCountryCode(countryCode)
    analytics.setUserProperties({
      'User Country': country,
      'User Country Source': getCountrySource(),
      'User IP Address': getIPAddress(),
      'User Language': translateManager.getLanguage(),
      'AR Quick Look Support': quicklookSupportExists(),
      ...getUserBrowserProperties(),
    })
  }

  static async setUserEmail({ email, source, refetchExperiments = true }) {
    const previousEmail = UserManager.getUserEmail()
    if (email === previousEmail) {
      return
    }

    if (email) {
      email = email.toLowerCase()
    }
    let response
    userEmail.setUserEmail(email)
    try {
      response = await mixtilesAxios.post('v1/user/userCapturedData', {
        email,
      })
      logger.info('captured user email', response.data)
      if (response.data.firstUsername && refetchExperiments) {
        setCookie(
          INITIAL_USER_KEY,
          btoa(JSON.stringify(response.data.firstUsername)),
          { expires: 34560000 } // 400 days
        )
        await experimentManager.fetch(response.data.firstUsername)
        analytics.track(EVENT_NAMES.STICKY_EXPERIMENTS_APPLIED, {
          'First Username': response.data.firstUsername,
          'Previous Email': previousEmail,
          'New Email': email,
        })
        analytics.setUserProperties({
          'Initial User ID': response.data.firstUsername,
        })
      }
      UserManager.setIsNewEmail(response.data.isNewEmail)
      analytics.setUserEmail({
        email,
        trackEventName:
          productTypeState.getProductType() === PRODUCT_TYPES.ART
            ? '[Art] Email Captured'
            : 'Web Email Captured',
        isNewEmail: UserManager.isNewEmail(),
        trackProperties: {
          Source: source,
          'First Username': response.data.firstUsername,
        },
      })
      onEmailChange.next(email)
    } catch (e) {
      logger.error('Failed to capture user email', e)
    }
  }

  static getUserEmail() {
    return userEmail.getUserEmail()
  }

  static setUserFullName(name, persist = true) {
    StorageManager.set(USER_FULL_NAME_KEY, name)
    analytics.setUserFullName(name)
    if (persist) {
      triggerApi(updateAccountApi({ fullName: name }))
    }
  }

  static getUserFullName() {
    return StorageManager.get(USER_FULL_NAME_KEY)
  }

  static getUserSessionToken() {
    return UserManager.getUser().sessionToken
  }

  static getUserAttributions = async () => {
    try {
      const { data } = await mixtilesAxios.get('/v4/userAttributions')
      return data || {}
    } catch (error) {
      logger.error('Failed to fetch user attributions from server', error)
      return {}
    }
  }

  static getInitialUserParams = async () => {
    if (!initialUserParams) {
      initialUserParams = (await UserManager.getUserAttributions())
        .initialParams
    }
    return initialUserParams || {}
  }

  static markArtUser() {
    StorageManager.set(ART_USER_KEY, true)
  }

  static isArtUser() {
    return !!StorageManager.get(ART_USER_KEY)
  }

  static getLastVisitDate() {
    return StorageManager.get(LAST_VISIT_DATE_KEY)
  }

  static updateLastVisitDate() {
    StorageManager.set(LAST_VISIT_DATE_KEY, Date.now())
  }

  static getDaysSinceLastVisit() {
    const lastVisitDate = UserManager.getLastVisitDate()
    if (!lastVisitDate) return 0
    const today = new Date()
    const lastVisit = new Date(lastVisitDate)
    const diffTime = Math.abs(today - lastVisit)
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  }
}

UserManager.init() // TODO: reconsider

export default UserManager
