import { useLocation, useSearchParams } from '@remix-run/react'
import { useCallback } from 'react'

// Unfortunately, the set function from useSearchParams overrides the location.state (deleting old values which are required).
// Therefore, this is a wrapper function that merges the old state with the new state.
export function useStatePreservingSearchParams(
  ...initParams: Parameters<typeof useSearchParams>
) {
  const { state } = useLocation()
  const [get, set] = useSearchParams(...initParams)
  const statePersistentSet: typeof set = useCallback(
    (nextInit, navigateOpts) => {
      return set(nextInit, {
        ...navigateOpts,
        state: { ...state, ...navigateOpts?.state },
      })
    },
    [state, set]
  )

  return [get, statePersistentSet] as const
}
