import { useStatePreservingSearchParams } from '../utils/routerUtils'
import { useEffect, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'

const DIALOG_STATE_QUERY_PARAM = 'dialog_id'

export const useDialogRoute = (
  open: boolean,
  onClose?: (...args: any[]) => void
) => {
  const [dialogId] = useState(() => uuid())
  const [searchParams, setSearchParams] = useStatePreservingSearchParams()
  const isDialogOpen = searchParams.get(DIALOG_STATE_QUERY_PARAM) === dialogId
  const lastDialogOpenState = useRef(isDialogOpen)
  const shouldDisableDialogRoute = true

  const closeDialog = () => {
    if (dialogId === searchParams.get(DIALOG_STATE_QUERY_PARAM)) {
      setSearchParams(
        (prevParams) => {
          prevParams.delete(DIALOG_STATE_QUERY_PARAM)
          return prevParams
        },
        { preventScrollReset: true }
      )
    }
  }

  // @ts-ignore
  const onDialogClose = (...args) => {
    closeDialog()
    onClose?.(args)
  }

  useEffect(() => {
    if (shouldDisableDialogRoute) {
      return
    }
    if (open && lastDialogOpenState.current && !isDialogOpen && onClose) {
      onClose()
    }
    lastDialogOpenState.current = isDialogOpen
  }, [isDialogOpen])
  useEffect(() => {
    if (shouldDisableDialogRoute) {
      return
    }
    if (open) {
      setSearchParams(
        (prevParams) => {
          prevParams.set(DIALOG_STATE_QUERY_PARAM, dialogId)
          return prevParams
        },
        { preventScrollReset: true }
      )
    } else {
      closeDialog()
    }
  }, [open])
  return {
    isDialogOpen: shouldDisableDialogRoute ? open : isDialogOpen,
    onDialogClose: shouldDisableDialogRoute ? onClose! : onDialogClose,
  }
}
