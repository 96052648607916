import { RemotePhoto } from '../PhotoBank/PhotoBank.types'
import { analytics, EVENT_NAMES } from '../../services/Analytics/Analytics'
import StorageManager from '../../services/StorageManager'
import { STORAGE_KEY as PHOTO_BANK_STORAGE_KEY } from '../PhotoBank/PhotoBank.consts'

let wasInitialized = false

const syncPhotoBank = (accountPhotos: RemotePhoto[] | undefined = []) => {
  if (wasInitialized) {
    return
  }
  wasInitialized = true

  const currentPhotosInStorage = (StorageManager.get(PHOTO_BANK_STORAGE_KEY) ||
    []) as RemotePhoto[]

  const localPhotosIds = currentPhotosInStorage.map((i) => i.uid)

  const remotePhotos = accountPhotos.filter(
    (i) => !localPhotosIds.includes(i.uid)
  )
  if (remotePhotos.length > 0) {
    analytics.track(EVENT_NAMES.RESTORED_PHOTO_BANK, {
      'Photos Count': remotePhotos.length,
    })
    StorageManager.set(PHOTO_BANK_STORAGE_KEY, [
      ...currentPhotosInStorage,
      ...remotePhotos,
    ])
  }
}

export default syncPhotoBank
