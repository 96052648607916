import React, { PropsWithChildren, useContext, useState } from 'react'
import { DEFAULT_TILES_SOURCE, TILES_SOURCE } from './TilesProvider.consts'
import storageManager from '../../services/StorageManager'
import useOnMount from '../../hooks/useOnMount'

export type TilesSourceContextValue = {
  tilesSource: TILES_SOURCE
  updateTilesSource: (source: TILES_SOURCE) => void
}

interface WithTilesSourceProps {
  tilesSource: TilesSourceContextValue
}

export const TilesSourceContext =
  React.createContext<TilesSourceContextValue | null>(null)

const TILES_SOURCE_KEY = 'tiles-source'

export function loadTilesSource() {
  return storageManager.get(TILES_SOURCE_KEY) || DEFAULT_TILES_SOURCE
}

// Save each storage key per tiles source. Example: separate "selectedSize" storage key, so the photos tileSize won't affect the art and vice versa
export function getStorageKeyBySource(key: string, tilesSource: TILES_SOURCE) {
  if (tilesSource === TILES_SOURCE.PHOTO_STYLER) {
    return key
  } else {
    return `${key}-${tilesSource}`
  }
}

export function useTilesSource() {
  const context = useContext(TilesSourceContext)
  if (!context) {
    throw new Error('useTilesSource must be used within a TilesSourceProvider')
  }

  return context
}

type Props = {
  initialSource?: TILES_SOURCE
}

export function TilesSourceProvider({
  children,
  initialSource = DEFAULT_TILES_SOURCE,
}: PropsWithChildren<Props>) {
  const [tilesSource, setTilesSource] = useState<TILES_SOURCE>(initialSource)

  useOnMount(() => {
    storageManager.set(TILES_SOURCE_KEY, tilesSource)
  })

  const updateTilesSource = (source: TILES_SOURCE) => {
    storageManager.set(TILES_SOURCE_KEY, source)
    setTilesSource(source)
  }

  const contextValue: TilesSourceContextValue = {
    tilesSource,
    updateTilesSource,
  }

  return (
    <TilesSourceContext.Provider value={contextValue}>
      {children}
    </TilesSourceContext.Provider>
  )
}

export function withTilesSource<P extends WithTilesSourceProps>(
  Component: React.ComponentType<P>
) {
  return React.forwardRef<unknown, Omit<P, keyof WithTilesSourceProps>>(
    (props, ref) => (
      <TilesSourceContext.Consumer>
        {(contexts) => (
          <Component ref={ref} {...(props as P)} tilesSource={contexts!} />
        )}
      </TilesSourceContext.Consumer>
    )
  )
}
