import { useEffect, useState } from 'react'
import { MATERIAL_TYPES, TILE_SIZES } from '@mixtiles/web-backend-shared'
import { calculateDiscountPercent, formatPrice } from '../utils/utils'
import { translateManager as t } from '../services/TranslateManager'
import { WELCOME_COUPON } from '../services/PromoCodeManager'
import { usePricing } from '../components/Pricing/PricingProvider'
import { useProductType } from '../services/ProductTypesManager'
import { PRODUCT_TYPES } from '../services/ProductTypeState'

export default function useBundleDiscount({
  tileSize,
  materialType,
}: {
  tileSize: TILE_SIZES
  materialType: MATERIAL_TYPES
}) {
  const { productType } = useProductType()
  const isBundleSupported = ![
    PRODUCT_TYPES.PHOTO_BOOK,
    PRODUCT_TYPES.BUSINESS,
  ].includes(productType)
  // The useDiscount hook is a hook that is base on the usePricing hook, that gives additional info when both the
  // discount and pricing have been fetched and can extrapolate more info from the two when combined.
  const [isLoading, setIsLoading] = useState(true)
  const [bundleDescription, setBundleDescription] = useState<string | null>(
    null
  )
  const [subtitle, setSubtitle] = useState<string | null>(null)
  const { fetchDiscount, fetchPricingItem, pricingData } = usePricing()
  const discount = isBundleSupported
    ? fetchDiscount({ tileSize, materialType })
    : null
  const pricingItem = isBundleSupported
    ? fetchPricingItem({ tileSize, materialType })
    : null

  useEffect(() => {
    if (
      discount?.isLoading ||
      pricingItem?.isLoading ||
      !discount ||
      !pricingItem
    ) {
      // Return loading state or handle error if needed
      setIsLoading(true)
      return
    }

    if (discount?.value && 'bundleTiles' in discount?.value?.metadata) {
      setBundleDescription(
        t.get('general.promo_code_success_dialog.title.bundle', {
          v1: discount.value.metadata.bundleTiles,
          v2: formatPrice(
            discount.value.metadata.bundlePrice,
            discount.value.metadata.bundleCurrency
          ),
        })
      )

      const singleTilePrice = pricingItem.value
      setSubtitle(
        t.get('general.menu.use_code_to_save', {
          v1: WELCOME_COUPON,
          v2: `${calculateDiscountPercent(
            discount?.value?.metadata?.bundlePrice,
            singleTilePrice * discount?.value?.metadata?.bundleTiles
          )}`,
        })
      )
    }

    setIsLoading(false)
  }, [discount, pricingItem, discount?.isLoading, pricingItem?.isLoading])

  const originalBundlePrice =
    !pricingItem?.isLoading &&
    pricingItem?.value &&
    discount?.value &&
    'bundleTiles' in discount?.value?.metadata
      ? pricingItem.value * discount?.value?.metadata?.bundleTiles
      : null

  return {
    isLoading: isBundleSupported ? isLoading : false,
    setIsLoading,
    discount,
    bundleDescription,
    originalBundlePrice,
    subtitle,
    pricingData,
  }
}
