import {
  ART_ROUTES,
  ART_SCREENS,
  ART_VIEW_SUB_ROUTES,
} from '../config/art-consts'
import { getUrlParam } from '../../utils/UrlUtils'
import { matchPath } from '@remix-run/react'
import { getKeyByValue } from '../../utils/utils'
import { createLocation, parseLocation } from '../../utils/location'
import { createArtworkProductPreviewUrl } from '@mixtiles/web-backend-shared'
import { remixNavigationManager } from 'services/remixNavigationManager'

export const SEARCH_QUERY_PARAM = 'search'
export const ARTWORKS_QUERY_PARAM = 'artworks'

function _createSubUrl(subRouteName) {
  return function func({ baseUrl = document.location.pathname } = {}) {
    const urlParts = baseUrl.split('/')
    urlParts[urlParts.length - 1] = subRouteName
    return urlParts.join('/')
  }
}

function _createSubUrlNavigator(subRouteName) {
  return function func({ baseUrl = document.location.pathname, query } = {}) {
    artNavigationManager.goToPage(
      { pathname: _createSubUrl(subRouteName)({ baseUrl }), query },
      { keepUrlParams: true }
    )
  }
}

class ArtNavigationManager {
  constructor() {
    this.goToFrameSelection = _createSubUrlNavigator(
      ART_VIEW_SUB_ROUTES.FRAME_SELECTION
    )
    this.goToArtworksSelection = _createSubUrlNavigator(
      ART_VIEW_SUB_ROUTES.ARTWORKS_SELECTION
    )
    this.goToLayoutSelection = _createSubUrlNavigator(
      ART_VIEW_SUB_ROUTES.LAYOUT_SELECTION
    )
    this.goToSizeSelection = _createSubUrlNavigator(
      ART_VIEW_SUB_ROUTES.SIZE_SELECTION
    )

    this.getCustomizeSetUrl = _createSubUrl(ART_VIEW_SUB_ROUTES.CUSTOMIZE)
    this.getArtworksSelectionUrl = _createSubUrl(
      ART_VIEW_SUB_ROUTES.ARTWORKS_SELECTION
    )
  }

  getCurrentScreen() {
    const paths = Object.values(ART_ROUTES)

    const currentPath = paths.find((path) =>
      matchPath({ path }, window.location.pathname)
    )

    return getKeyByValue(ART_ROUTES, currentPath)
  }

  goToHome() {
    this.goToPage(ART_ROUTES[ART_SCREENS.HOME])
  }

  // We choose not to keep browser history of this URL because after the user finished his onboarding, we would like for the user to go back straight to the homepage.
  // So we ignore it from the browser history.
  goToOnboarding() {
    this.goToPage(ART_ROUTES[ART_SCREENS.ONBOARDING], {
      keepUrlParams: false,
      keepHistory: true,
    })
  }

  goToDiscovery({ isNew = true } = {}) {
    const screenName = ART_SCREENS.DISCOVERY
    this.goToPage(ART_ROUTES[screenName], { isNew })
  }

  getDiscoveryLocation({ isNew = true } = {}) {
    const screenName = ART_SCREENS.DISCOVERY
    return createLocation({
      location: ART_ROUTES[screenName],
      isNew,
    })
  }

  goToSearch({ isNew = true } = {}) {
    const screenName = ART_SCREENS.SEARCH

    const searchQuery = getUrlParam(SEARCH_QUERY_PARAM)

    const query =
      searchQuery && !isNew
        ? {
            search: searchQuery,
          }
        : {}

    this.goToPage({ pathname: ART_ROUTES[screenName], query }, { isNew })
  }

  getCategoryPageLocation({ categoryName, isNew = true }) {
    const screenName = ART_SCREENS.CATEGORY
    const categoryUrl = ART_ROUTES[screenName].replace(
      ':category',
      categoryName
    )

    return createLocation({
      location: categoryUrl,
      isNew,
    })
  }

  getCategorySetsLocation({ categoryName, isNew = true }) {
    const pathname = ART_ROUTES[ART_SCREENS.CATEGORY_SETS].replace(
      ':categoryName',
      categoryName
    )

    return createLocation({
      location: {
        pathname,
      },
      isNew,
    })
  }

  getSearchLocation({ search, focus = true, isNew = true }) {
    return createLocation({
      location: {
        pathname: ART_ROUTES[ART_SCREENS.SEARCH],
        query: { search, focus },
      },
      isNew,
    })
  }

  getArtistPageLocation({ artistName, isNew = true }) {
    const screenName = ART_SCREENS.ARTIST
    const artistUrl = ART_ROUTES[screenName].replace(':artistName', artistName)
    const query = { fromArt: true }

    return createLocation({
      location: {
        pathname: artistUrl,
        query,
      },
      isNew,
    })
  }

  getArtworkProductPreviewLocation({
    categoryName,
    artworkId,
    fromScreen,
    searchQuery,
  }) {
    const artworkPrintViewUrl = createArtworkProductPreviewUrl(
      categoryName,
      artworkId
    )
    return createLocation({
      location: {
        pathname: artworkPrintViewUrl,
        query: { fromScreen, searchQuery },
      },
      keepUrlParams: true,
      endWithSlash: true,
    })
  }

  goToPage(
    location,
    { state, keepUrlParams = false, keepHistory = true } = {
      keepUrlParams: false,
      keepHistory: true,
      isNew: true,
    }
  ) {
    const locationObject = parseLocation(location, keepUrlParams)

    remixNavigationManager.navigate(
      {
        pathname: locationObject.pathname,
        hash: locationObject.hash,
        search: locationObject.search,
      },
      { state, replace: !keepHistory }
    )
  }
}

export const artNavigationManager = new ArtNavigationManager()
