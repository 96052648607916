import React, { Ref } from 'react'
import { Group } from 'three'

import { BookSize, BookTextStyle } from '@mixtiles/web-backend-shared'
import type { MotionValue } from 'framer-motion'
import { Photo, PickedPhoto } from '../../PhotoBank/PhotoBank.types'
import { Crop } from '../../../utils/cropUtils.types'
import { UploadProgress } from '../../UploadProgressDialog/UploadProgressDialog'

export type BookBasicData = { captionText: string; coverPhotoUid: string }

export type BookBasicDataWithCoverPhoto = BookBasicData & {
  coverPhotoUrl: string
}

export type PagePhoto = {
  uid: string // PhotoBank uid
  dateTaken: number | undefined
  cropParams: Record<number, Crop>
  lowQualityApproved?: boolean
  fixedIndex?: boolean
}

export type PageSideContent = {
  photos: PagePhoto[]
  // can be layout, color, label in the future
}

export type PageIndex = {
  pageIndex: number
  pageSide: PageSide
}

export type CoverHighlightState = 'photo' | 'title' | null

export enum PageSide {
  FRONT = 'front',
  BACK = 'back',
}

export type Page = {
  front: PageSideContent
  back: PageSideContent
  pageSide: PageSide
  ref?: React.RefObject<Group>
}

export type Caption = {
  // This parameter is sent to the backend as the font size in the pdf
  // it comes from the client since it involves frontend calculations that
  // are harder to do on the backend
  fontHeight?: number
  text: string
  style: BookTextStyle
}

export type Cover = {
  caption: Caption
  photo: PagePhoto
  isFrontOpen: boolean
  isBackOpen: boolean
}

export interface CurrentPageHover {
  pageSide: PageSide
  pageIndex: number
}

export interface FlipPageParams {
  closeCover: boolean
  source:
    | 'Swipe'
    | 'Arrow Key'
    | 'Arrow Button'
    | 'Slider'
    | 'Moving Page'
    | 'Arrange Tutorial'
}

export type BookMotionRect = {
  top: MotionValue<number>
  bottom: MotionValue<number>
  left: MotionValue<number>
  right: MotionValue<number>
  middleY: MotionValue<number>
  middleX: MotionValue<number>
  leftPageMiddleX: MotionValue<number>
  rightPageMiddleX: MotionValue<number>

  width: MotionValue<number>
  height: MotionValue<number>
  cameraX: MotionValue<number>
  cameraY: MotionValue<number>
  worldUnitsPerPixel: MotionValue<number>
}

export enum PhotoBookOrderValidation {
  VALID = 'valid',
  NOT_ENOUGH_PHOTOS = 'not_enough_photos',
}

export interface PhotoBookContextType {
  bookSize: BookSize
  setBookSize: React.Dispatch<React.SetStateAction<BookSize>>
  cover: Cover | null
  setCover: (cover: Cover) => void
  pages: Page[]
  updatePages: (pages: Page[]) => void
  updateRef: (ref: Ref<Group | undefined>, pageIndex: number) => void
  addPhotos: (
    files: PickedPhoto[],
    cloudServiceName: string | null,
    shouldOrderPhotos: boolean
  ) => void
  flipRight: (params: FlipPageParams) => void
  flipLeft: (params: FlipPageParams) => void
  highlightedPageIndex: number | null
  highlightPage: (pageIndex: number | null) => void
  coverHighlightState: CoverHighlightState
  isCoverHighlighted: boolean
  highlightCover: (highlightState: CoverHighlightState) => void
  currentPageIndex: number
  isBookInitialized: boolean
  updatePendingCropParams: (crop: Crop) => void
  deletePage: (pageIndex: number, pageSide: PageSide) => void
  goToCover: () => Promise<void>
  goToPage: (pageIndex: PageIndex) => void
  usePhotoAsCover: (photoUid: string, ignorePendingCropParams?: boolean) => void
  updatePagePhoto: (
    pageIndex: number,
    pageSide: PageSide,
    photo: PagePhoto
  ) => void
  updateCoverText: (text: string) => void
  updateCoverStyle: (style: BookTextStyle) => void
  updateCoverFontHeight: (fontHeight: number) => void
  updatePhotoRendered: (uid: string) => void
  isSwiping: boolean
  deleteBook: () => void
  addingPhotosProgress: UploadProgress
  showCover: () => Promise<void>
  orderValidationStatus: PhotoBookOrderValidation
  setOrderValidationStatus: (status: PhotoBookOrderValidation) => void
  validatePhotoBookOrder: () => PhotoBookOrderValidation
  isBookClosedOnFront: boolean
  isBookClosedOnBack: boolean
  isBookOpened: boolean
  isBookAnimating: boolean
  currentPageHover: CurrentPageHover | undefined
  setCurrentPageHover: React.Dispatch<
    React.SetStateAction<CurrentPageHover | undefined>
  >
  resetBookNavigationState: () => void
  isRenderPaused: boolean
  setIsRenderPaused: React.Dispatch<React.SetStateAction<boolean>>
  desktopSliderValue: number
  setDesktopSliderValue: React.Dispatch<React.SetStateAction<number>>
  bookMotionRect: BookMotionRect
  showInitialHint: boolean
  showKeyboardHint: boolean
  photosInChronologicalOrder: boolean
  setShowInitialHint: (show: boolean) => void
  setShowKeyboardHint: (show: boolean) => void
  globalPointerDown: boolean
  setGlobalPointerDown: React.Dispatch<React.SetStateAction<boolean>>
  lowResPhotos: Photo[]
  approveLowResPhotos: (uids: string[]) => void
  isFastSwiping: boolean
  setIsFastSwiping: (isFastSwiping: boolean) => void
  isZooming: boolean
  setIsZooming: (isZooming: boolean) => void
  pageToDelete: { pageIndex: number; pageSide: PageSide } | null
  setPageToDelete: React.Dispatch<
    React.SetStateAction<{ pageIndex: number; pageSide: PageSide } | null>
  >
  setIsCropping: React.Dispatch<React.SetStateAction<boolean>>
  isCropping: boolean
  isCoverRendered: boolean
  setIsCoverRendered: React.Dispatch<React.SetStateAction<boolean>>
  preparePhotoBookForInitialization: (isLocal: boolean) => void
}
