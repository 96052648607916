import { createTheme, ThemeOptions } from '@mui/material'
import { generateMuiTheme, BREAKPOINTS } from './defaultTheme'
import { BRAND_COLORS } from './photosTheme'

export const ART_THEME = 'Art'

export const theme = {
  name: ART_THEME,
  breakpoints: BREAKPOINTS,
  colors: {
    ...BRAND_COLORS,
    primary: '#261F23',
    black: '#261F23',
    white: '#FFF',
    lightBlack: '#333333',
    gray: '#D4D2D3',
    gray3: '#EDEDED', // same as photos
    gray7: '#808080', // same as photos
    gray8: '#BFBFBF', // same as photos
    darkerGray: '#938F91',
    lightGray: '#F6F6F6',
    hoverGray: '#EEEDED',
    pink: '#FF0077',
    mainPink: '#FF0072', // same as pink3 on mixin
  },
}

const createArtistPlatformTheme = (muiThemeObject: ThemeOptions) => {
  const copy = { ...muiThemeObject }
  if (copy?.components?.MuiSelect?.defaultProps?.disableUnderline) {
    delete copy.components.MuiSelect.defaultProps.disableUnderline
  }
  return createTheme(copy)
}

const muiThemeObject = generateMuiTheme(theme.colors, 'ProximaNova')
export const muiTheme = createTheme(muiThemeObject)
export const artistPlatformMuiTheme = createArtistPlatformTheme(muiThemeObject)
