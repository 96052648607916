import { getTiles } from '../pages/PhotoStyler/tilesSaver'
import {
  STORAGE_KEYS,
  TILES_SOURCE,
} from '../components/TilesProvider/TilesProvider.consts'
import {
  ART_COLLECTION_BROWSE_URL,
  ART_COLLECTION_DESIGNER_URL,
} from '../config/config'
import { isClient } from './runtimeUtils'

export function getCollectionNavigationPath() {
  const tiles = isClient() ? getTiles(STORAGE_KEYS[TILES_SOURCE.ART]) : []
  return tiles.length > 0
    ? ART_COLLECTION_DESIGNER_URL
    : ART_COLLECTION_BROWSE_URL
}
